import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { updateCountPerPage } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage as _itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { openS3Document } from 'services'
import { filterPackages } from 'services/apis/docsign'
import { svgSearch } from 'stories/assets'
import { Input2, Pagination } from 'stories/components'
import { formatTime } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { renderHeader } from 'utils/table'
export interface IPackage {
  id: number
  packageId: string
  account: string
  finalFileKey: string
  loanNumber: string
  name: string
  documentNames: string[]
  worksheetId: string
  webhookUrl: string
  returnUrl: string
  status: string
  events: IEvent[]
  createdAt: string
  updatedAt: string
}

export interface IEvent {
  actor: string
  action: string
  ipAddr: string
  createdAt: string
}

export const DocSign = ({ isLoanApplication = false }: { isLoanApplication?: boolean }) => {
  console.log('isLoanApplication', isLoanApplication)
  const [isLoading, setLoading] = useState(false)
  const [list, setList] = useState<IPackage[]>([])
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'updatedAt',
    orderDir: '0',
    isLoanApplication: isLoanApplication,
  })
  const { itemCountPerPage = _itemCountPerPage } = useSelector((state: any) => state.auth)
  const [total, setTotal] = useState(0)
  const [action, setAction] = useState('')
  const [pageNum, setPageNum] = useState(0)
  const [_countPerPage, setCountPerPage] = useState(itemCountPerPage)
  const [filterQuery, setFilterQuery] = useState('')
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useTitle(`Sign`)
  useEffect(() => {
    if (action) return
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [pageNum, _countPerPage])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => action == '' && filterData(filters, 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const onChangeFilter = (key: string, value: any, needRefetch = true) => {
    if (!isGetUsersOnce) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (needRefetch) {
      filterData(newFilters, 0)
      setPageNum(0)
    }
  }

  const filterData = async (filters: any, _pageNum: number = -1) => {
    if (action) return
    if (_pageNum === -1) _pageNum = pageNum
    const filterData = {
      ...filters,
      skip: pageNum * _countPerPage,
      count: _countPerPage,
    }
    setAction('getLoans')
    setLoading(true)

    const { data, total } = await filterPackages(filterData)
    setList(data)
    setTotal(total)
    setLoading(false)
    setAction('')
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeCountPerPage = (count: number) => {
    dispatch(updateCountPerPage(count))
    setCountPerPage(count)
    setPageNum(0)
  }

  const onSort = (key: string, sortOrder: number) => {
    if (sortOrder == 0) sortOrder = -1
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
    filterData(newFilters)
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="flex flex-wrap items-center gap-2 mb-3">
          <h1 className="text-2xl font-variation-settings-600">
            <span>Sign</span>
          </h1>
          <div className="flex-1" />
        </div>
        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-3">
          <Input2
            type="search"
            title="Search ..."
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-2">No</th>
                {renderHeader({
                  title: 'Name',
                  key: 'name',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'name' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Account',
                  key: 'account',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'account' ? parseInt(filters.orderDir) : 0,
                })}

                {renderHeader({
                  title: 'LoanNumber',
                  key: 'loanNumber',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'loanNumber' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'WorksheetId',
                  key: 'worksheetId',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'worksheetId' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Status',
                  key: 'status',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'status' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Updated At',
                  key: 'updatedAt',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'updatedAt' ? parseInt(filters.orderDir) : 0,
                  className: 'w-48',
                })}
                <th className="px-2 w-32">Actions</th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {list.map((item: IPackage, index: number) => {
                return (
                  <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span
                        onClick={() => navigate('/sign/item', { state: { package: item } })}
                        className="flex-col flex"
                      >
                        {index + 1 + pageNum * _countPerPage}
                      </span>
                    </td>
                    <td className="px-2 font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span onClick={() => navigate('/sign/item', { state: { package: item } })}>
                        {item.documentNames.length ? item.documentNames.join(' / ') : item.name}
                      </span>
                    </td>
                    <td className="px-2 py-2 w-24">
                      <span>{item.account}</span>
                    </td>

                    <td className="px-2 py-2">
                      <span>{item.loanNumber}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span>{item.worksheetId}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span>{item.status}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span>{`${formatTime(item.updatedAt)}`}</span>
                    </td>
                    {item.finalFileKey ? (
                      <td className="px-2 py-2">
                        <div
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer inline-block"
                          onClick={() => openS3Document(item.finalFileKey, false)}
                        >
                          <ArrowDownTrayIcon className="w-4 h-4" />
                        </div>
                      </td>
                    ) : (
                      <td className="px-2 py-2"></td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end items-center mt-3">
          <div className="flex-1" />
          <Pagination
            totalCount={total}
            itemCountPerPage={_countPerPage}
            onNavigate={onPageNavigate}
            onChangeCountPerPage={onChangeCountPerPage}
            pageNum={pageNum}
          />
        </div>
      </div>
    </div>
  )
}
